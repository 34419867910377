<template>
  <full-model-view
    progress-dot
    :visible="visible"
    :title="title"
    :sub-title="subTitle"
    @cancel="handleCancel"
  >
    <div slot="up-extra" >
    </div>
    <div>
      <MyForm :type="type" @close="close" :officialId="officialId" />
    </div>
  </full-model-view>
</template>

<script>
import { FullModelView } from '@/components'
import MyForm from './formLabel.vue'
export default {
  name: 'StepsLabel',
  components: {
    FullModelView,
    MyForm
  },
  data() {
    return {
      title: '详情',
      subTitle: '',
      visible: false,
      officialId: '',
      submitLoading: false,
      type: ''
    }
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    open({ type, id }) {
      this.officialId = id
      this.visible = true
      this.type = type
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      if (this.$route.query.id) {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped>
.add-steps-box{
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}
.add-content-box{
  margin: 0 auto;
  /* width: 1200px; */
  padding: 0px 160px;
  box-sizing: border-box;
}
.add-content-block{
  margin: 0 auto;
}
.add-steps-actions{
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
